import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { initGA, logPageView } from "../../Analytics";
import "./About.css";
import { WDQMS_VERSION } from "../../utils/general";
const About = props => {
  // Google analytics
  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      initGA();
      logPageView();
    }
  }, []);
  return (
    <div className="presentation">
      <p>
        The WDQMS webtool is a resource developed by the World Meteorological
        Organization{" "}
        <a
          href="https://public.wmo.int/"
          target="_blank"
          rel="noopener noreferrer"
        >
          WMO
        </a>
        , and hosted by the European Centre for Medium-Range Weather Forecasts (
        <a
          href="https://www.ecmwf.int/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ECMWF
        </a>
        ), to monitor the performance of all WMO Integrated Global Observing
        System (
        <a
          href="https://public.wmo.int/en/programmes/wigos"
          target="_blank"
          rel="noopener noreferrer"
        >
          WIGOS
        </a>
        ) observing components.
      </p>
      <p>
        <p>
          The current operational version ({WDQMS_VERSION}) of the webtool
          provides the following three monitoring modules:
        </p>
        <p>
          {" "}
          <b>
            Near real time monitoring of the Global Observing System (land
            stations)
          </b>
        </p>
        <p>
          This module monitors the availability and quality of observational
          data based on near-real-time monitoring information from the four
          participating global Numerical Weather Prediction (NWP) centres: the
          German Weather Service (<b>DWD</b>
          ), the European Centre for Medium range Weather Forecasts (
          <b>ECMWF</b>
          ), the Japan Meteorological Agency (<b>JMA</b>) and the United States
          National Centers for Environmental Prediction (<b>NCEP</b>
          ). The webtool links availability and quality of surface-based
          observational data from those WIGOS Quality Monitoring Centres with
          the WIGOS metadata and user requirements from{" "}
          <a
            href="https://oscar.wmo.int/surface"
            target="_blank"
            rel="noopener noreferrer"
          >
            OSCAR/Surface
          </a>
          , providing free and open access information on network/station issues
          to any user, in particular to WMO Members and to Regional WIGOS
          Centres (RWCs) for follow up.
        </p>
        <p>
          For this near-real-time NWP monitoring module, the data{" "}
          <b>availability</b> is obtained by comparing the observations received
          by the WIGOS Monitoring Centres via{" "}
          <a
            href="https://public.wmo.int/en/about-us/vision-mission-strategic-priorities/wmo-information-system-wis"
            target="_blank"
            rel="noopener noreferrer"
          >
            GTS/WIS{" "}
          </a>{" "}
          to those expected according to the schedule of international exchange
          declared in{" "}
          <a
            href="https://oscar.wmo.int/surface"
            target="_blank"
            rel="noopener noreferrer"
          >
            OSCAR/Surface
          </a>
          . The data <b>quality</b> is obtained by comparing the Observation
          minus Background (O-B) departures, provided by the WIGOS Monitoring
          Centres, against the up-to-date observational{" "}
          <a
            href="https://space.oscar.wmo.int/applicationareas/view/2_1_global_numerical_weather_prediction_and_real_time_monitoring"
            target="_blank"
            rel="noopener noreferrer"
          >
            requirements of global NWP
          </a>{" "}
          defined in{" "}
          <a
            href="https://space.oscar.wmo.int/requirements"
            target="_blank"
            rel="noopener noreferrer"
          >
            OSCAR/Requirements
          </a>{" "}
          .
        </p>
        <p>
          The near-real-time NWP monitoring of data availability and data
          quality is presented for three different periods:
          <b>6-hourly</b> temporal aggregation centred on the main synoptic
          hours (00, 06, 12 and 18UTC); <b>daily</b> aggregation; <b>monthly</b>{" "}
          aggregation. An extra level of aggregation (<b>Alert</b>) based on
          5-day moving average across the WIGOS Monitoring Centres is available
          to warn users, in particular the RWCs for potential observational
          issues that should be followed up. The NWP monitoring of the Global
          Observing System (
          <a
            href="https://public.wmo.int/en/programmes/global-observing-system"
            target="_blank"
            rel="noopener noreferrer"
          >
            GOS
          </a>
          ) is currently available for:{" "}
        </p>
        <div>
          <ul>
            {" "}
            <li>
              <a
                href="https://wdqms.wmo.int/nwp/land_surface"
                target="_blank"
                rel="noopener noreferrer"
              >
                Surface land stations (SYNOP reports)
              </a>
            </li>
            <li>
              <a
                href="https://wdqms.wmo.int/nwp/land_upper-air"
                target="_blank"
                rel="noopener noreferrer"
              >
                Upper-air (radiosonde) land stations (TEMP and PILOT reports)
              </a>
            </li>
            <li>
              <a
                href="https://wdqms.wmo.int/nwp/marine_surface"
                target="_blank"
                rel="noopener noreferrer"
              >
                Surface marine stations from ship, moored and drifting buoys
                (SHIP and BUOY reports)
              </a>
            </li>
          </ul>
        </div>
        <p>
          The monitoring is applied only to the observed quantities whose O-B
          departures are available in the NWP monitoring reports, i.e. the ones
          whose model equivalent is available from the NWP assimilation systems.
          Therefore, the surface physical variables monitored are the following:
          <b>surface pressure</b> (or <b>geopotential height</b>, for some land
          stations in mountainous areas), <b>2-metre temperature</b>,{" "}
          <b>2-metre relative humidity</b> and <b>10-metre wind</b> (meridional
          and zonal components). For the upper-air land observations, the
          variables are: <b>air temperature</b>, <b>relative humidity</b> and
          wind, both meridional and zonal components.
        </p>
        <p>
          <b>Global Basic Observing Network (land stations)</b>
        </p>
        <p>
          The Global Basic Observing Network (GBON) module monitors GBON
          stations according to the GBON compliance criteria. Similarly to the
          near-real-time NWP monitoring module it is divided into surface (SYNOP
          reports) and upper-air (radiosonde) land stations (TEMP and PILOT
          reports). The main differences between this GBON module and the
          near-real-time monitoring module are:
        </p>
        <p>
          <div>
            <ul>
              {" "}
              <li>
                It monitors only those stations registered in OSCAR/Surface that
                have been approved as GBON stations.
              </li>
              <li>
                The stations shown in the GBON module are those that meet the
                GBON compliance criteria, so there is not option to select
                availability, timeliness or data quality separately.
              </li>
              <li>
                The best results among the various WIGOS Monitoring Centres are
                always shown, so there is no option to select the results from a
                particular centre.
              </li>
            </ul>
          </div>
        </p>
        <p>
          The baseline for the data availability is fixed, based on the GBON
          requirements, i.e. the expected figures of data received are fixed for
          all surface stations (24 reports per day) and for all upper-air
          stations (2 per day), so, the baseline is not extracted from
          OSCAR/Surface as it is for the near-real-time monitoring module
        </p>
        <p>
          <b>Monitoring of Global Climate Observing System (land stations)</b>
        </p>
        In addition, a monitoring module of the Global Climate Observing System
        (
        <a
          href="https://gcos.wmo.int/"
          target="_blank"
          rel="noopener noreferrer"
        >
          GCOS
        </a>
        ) based on data collected from GCOS monitoring centres is available for
        both GCOS Surface Network (
        <a
          href="https://gcos.wmo.int/en/networks/atmospheric/gsn"
          target="_blank"
          rel="noopener noreferrer"
        >
          GSN
        </a>
        ) and GCOS Upper-Air Network (
        <a
          href="https://gcos.wmo.int/en/networks/atmospheric/guan"
          target="_blank"
          rel="noopener noreferrer"
        >
          GUAN
        </a>
        ). The GCOS monitoring is based on monthly statistics provided by DWD.
      </p>

      <p>
        In the GCOS module, the monitoring of the GUAN data availability is
        obtained by comparing the monthly statistics against the GCOS minimum
        requirements. The data quality is based on the monthly averages of O-B
        departures compared against the observational requirements for climate
        application defined in OSCAR/Requirements. For the GSN component, the
        availability is obtained by checking the collected monthly CLIMAT
        messages against the list of stations affiliated to the GCOS programme
        and registered in OSCAR/Surface as such
      </p>

      <p>The monitoring of GCOS networks is available for:</p>
      <div>
        <ul>
          <li>
            <a
              href="https://wdqms.wmo.int/gcos/land_surface"
              target="_blank"
              rel="noopener noreferrer"
            >
              Surface land stations (GSN)
            </a>
          </li>
          <li>
            <a
              href="https://wdqms.wmo.int/gcos/land_upper-air/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Upper-air land stations (GUAN)
            </a>
          </li>
        </ul>
      </div>

      <p>
        The variables included in GSN monitoring are:{" "}
        <b>daily mean surface pressure</b>, <b>daily mean air temperature</b>,{" "}
        <b>daily maximum air temperature</b>,{" "}
        <b>daily minimum air temperature</b>, <b>daily mean water vapour</b>,{" "}
        <b>total daily precipitation</b> and{" "}
        <b>total daily sunshine duration</b>.
      </p>
      <p>
        The monitoring of the GUAN stations only covers the radiosonde
        observations provided in the Binary Universal Form for Representation of
        meteorological data (BUFR) reports. The variables considered in the GUAN
        are: <b>air temperature</b>, relative <b>humidity</b> and <b>wind</b>.
      </p>
      <p>
        For support please contact the{" "}
        <a
          href="https://etrp.wmo.int/mod/forum/view.php?id=10062"
          target="_blank"
          rel="noopener noreferrer"
        >
          user forum
        </a>{" "}
        of the WIGOS Learning Portal in the WMO Moodle site.
      </p>
    </div>
  );
};

export default About;
